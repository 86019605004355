<template>
  <v-form v-if="this.isDadosPessoaisCarregados" ref="inscricaoForm">
    <DadosPessoaisForm
        v-model="inscricao.dadosPessoais"
        :pode-alterar-todos-os-campos="false"
    />
    <v-card-text v-if="processo.tipoProcesso.codigo === 'THEM'">
      <v-alert
          type="info"
          outlined
          colored-border
      >
        Você autoriza que, eventualmente, seja feito contato com seu número de telefone móvel, via aplicativo de mensagem instantânea, a fim de, em caráter meramente complementar e discricionário, informá-lo acerca de prazos e procedimentos previstos no Edital nº 892/2024, nos termos da LEI Nº 13.709, DE 14 DE AGOSTO DE 2018 (Lei Geral de Proteção de Dados Pessoais)? Salientamos que esta autorização não desobriga o candidato de acompanhar as informações disponíveis no endereço eletrônico https://acessograduacao.ufrj.br/, conforme disposto no Art. 13 do Edital nº 892/2024.
        <v-select
            v-model="inscricao.dadosPessoais.concordouTelefone"
            :items="opcoesConcordo"
            :rules="[campoObrigatorioRule]"
            dense
            item-text="label"
            label="Escolha uma opção*"
            outlined
            item-value="value"
            validate-on-blur
            class="mt-3"
        />
      </v-alert>
    </v-card-text>
    <v-card v-if="processo.possuiNecessidadesEspeciais" flat>
      <v-card-title class="tituloForm">
        Necessidades Especiais
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-select
              v-model="inscricao.necessidadesEspeciais"
              :items="necessidadesEspeciais"
              dense
              item-text="tipoNecessidadeEspecial.descricao"
              label="Necessidades Especiais"
              multiple
              outlined
              return-object
              small-chips
          ></v-select>
          <v-textarea
              v-if="possuiNecessidadeEspecialOutra"
              v-model="necessidadeEspecialOutra.descricaoOutra"
              :counter="255"
              :rules="[campoObrigatorioRule]"
              auto-grow
              dense
              label="Descreva a sua necessidade especial *"
              name="inscricaoNecessidadeEspecialOutraDescricao"
              maxlength="255"
              outlined
              rows="1"
          ></v-textarea>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-title class="tituloForm">
        Inscrição
      </v-card-title>
      <v-card-text>
        <component
            :is="inscricaoComponente"
            ref="inscricaoComponente"
            :inscricao="inscricao"
            :processo="processo"
            v-model="inscricaoEspecifica"
        />
      </v-card-text>
    </v-card>
    <PerfilSocioEconomico
        ref="moduloPerfilSocioEconomico"
        v-if="processo.possuiCota"
        v-model="inscricao.perfilSocioEconomico"
        :perfilSocioEconomico="inscricao.perfilSocioEconomico"
    />
    <AcaoAfirmativa
        ref="moduloAcaoAfirmativa"
        v-if="inscricao.perfilSocioEconomico.escolaPublica
              && processo.possuiCota"
        v-model="inscricao.acaoAfirmativa"
        :acaoAfirmativa="inscricao.acaoAfirmativa"
        :perfilSocioEconomico="inscricao.perfilSocioEconomico"
    />
    <EnvioDocumentosInscricao
        ref="envioDocumentosInscricao"
        v-if="processo.possuiEnvioDocumentos"
        v-model="arquivosEscolhidos"
    >
      <template v-slot:instrucoes v-if="processo.instrucoesEnvioDocumentos">
        <v-card-subtitle class="font-weight-bold mt-1">Instruções:</v-card-subtitle>
        <v-card-text>
          <p class="text-justify mb-n4">
            {{ processo.instrucoesEnvioDocumentos }}
          </p>
        </v-card-text>
      </template>
    </EnvioDocumentosInscricao>
    <v-card flat>
      <v-card-text>
        <v-checkbox
            v-model="concordouEdital"
            style="margin-top:-2px"
            :label="
            `Li e concordo com os termos do edital do processo ${processo.nome} da UFRJ ${processo.ano}. *`
          "
            color="success"
            dense
            :rules="[campoObrigatorioCheckBoxRule]"
        ></v-checkbox>
      </v-card-text>
    </v-card>
    <v-btn block color="primary" @click="salvar()">
      Finalizar Inscrição
    </v-btn>
  </v-form>
</template>

<script>
import rules from "@/commons/rules";
import {mapActions} from "vuex";
import dadosPessoaisService from "@/services/dadosPessoais.service";
import tipoNecessidadeEspecialService from "@/services/tipoNecessidadeEspecial.service";
import DadosPessoaisForm from "@/components/base/forms/DadosPessoaisForm";
import AcaoAfirmativa from "@/components/pages/Inscricao/AcaoAfirmativa.vue";
import EnvioDocumentosInscricao from "@/components/pages/Inscricao/EnvioDocumentosInscricao";
import inscricaoService from "@/services/inscricao.service";
import PerfilSocioEconomico from "@/components/pages/Inscricao/PerfilSocioEconomico.vue";

export default {
  name: "InscricaoForm",

  props: {
    processo: {type: Object, required: true}
  },

  components: {
    PerfilSocioEconomico,
    DadosPessoaisForm,
    AcaoAfirmativa,
    EnvioDocumentosInscricao,
    InscricaoFormMUD: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormMUD.vue"),
    InscricaoFormMUF: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormMUF.vue"),
    InscricaoFormTHE: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormTHE.vue"),
    InscricaoFormTHEM: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormTHEM.vue"),
    InscricaoFormREIN: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormREIN"),
    InscricaoFormTEE: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormTEE.vue"),
    InscricaoFormTEF: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormTEF.vue"),
    InscricaoFormTCE: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormTCE.vue"),
    InscricaoFormIVTHE: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormIVTHE.vue"),
    InscricaoFormIVLIB: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormIVLIB.vue"),
    InscricaoFormICA: () =>
        import("@/components/pages/Inscricao/Processos/InscricaoFormICA.vue")
  },

  data() {
    return {
      opcoesConcordo: [
        { label: 'Autorizo', value : true},
        { label: 'Nao Autorizo', value : false}
      ],
      inscricao: {
        dadosPessoais: {},
        opcao: {
          instrumentoMusicalRequerido: false
        },
        necessidadesEspeciais: [],
        inscricaoInstrumentoMusical: {},
        acaoAfirmativa: {
          concordouVagasDaLei: null,
          estouCiente:false,
          modalidadeEscolar: null,
          modalidadeRacial: null,
          modalidadePCD: null,
          modalidadeRenda: null,
          modalidadeQuilombola: null,
        },
        perfilSocioEconomico: {
          quilombola: null,
          pcd: null,
          escolaPublica: null,
          renda: null,
          corRaca: null
        }
      },
      concordouEdital: false,
      loading: true,
      inscricaoOriginal: null,
      exibirModalMudancaOpcaoCurso: false,
      exibirModalAlteracaoInscricao: false,
      arquivosEscolhidos: [],
      inscricaoEspecifica: {}
    };
  },

  async created() {
    let loader = this.$loading.show();

    await tipoNecessidadeEspecialService
        .recuperarTiposNecessidadeEspecial()
        .then(response => {
          const tiposNE = [];
          let tiposNecessidadeEspecial = response.data;
          tiposNecessidadeEspecial.forEach(tne =>
              tiposNE.push({
                tipoNecessidadeEspecial: tne,
                descricaoOutra: null
              })
          );
          this.necessidadesEspeciais = tiposNE;
        })
        .catch(() => {
        });

    await dadosPessoaisService
        .recuperarDadosPessoaisUsuarioLogado()
        .then(response => {
          this.inscricao.dadosPessoais = response.data;
        })
        .catch(() => {
        });

    this.loading = false;
    loader.hide();
  },

  computed: {
    isDadosPessoaisCarregados() {
      return !this.loading;
    },

    possuiNecessidadeEspecialOutra() {
      return (
          this.inscricao.necessidadesEspeciais &&
          this.inscricao.necessidadesEspeciais.some(
              ne => ne && ne.tipoNecessidadeEspecial.codigo === "OUTRA"
          )
      );
    },

    necessidadeEspecialOutra() {
      return this.inscricao.necessidadesEspeciais.find(
          ne => ne.tipoNecessidadeEspecial.codigo === "OUTRA"
      );
    },

    inscricaoComponente() {
      return "InscricaoForm" + this.processo.tipoProcesso.codigo;
    }
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    async salvar() {
      if (this.$refs.inscricaoForm.validate()) {
        await this.inscrever();
      } else {
        this.exibirAviso({
          mensagem: "Campos obrigatórios não preenchidos",
          tipo: "error"
        });
      }
    },

    async inscrever() {
      let loader = this.$loading.show();
      await inscricaoService
          .salvarInscricaoUsuarioLogado(this.processo.oid, this.arquivosEscolhidos, this.inscricaoEspecifica)
          .then(() => {
                loader.hide();
                this.$emit("salvar");
              }
          ).catch(error => {
            loader.hide();
            let mensagem = "Não foi possível salvar inscrição";
            let tipo = "error";
            if(error.response.data.codigoErro === 'CP01')
            {
              mensagem += ": Erro ao preencher endereço, favor usar a lupa no campo CEP deste formulário";
            } else if (error.response.status === 409) {
              mensagem += ": " + error.response.data.msg;

              if (error.response.data.codigoErro === 'IN01' || error.response.data.codigoErro === 'IN02') {
                tipo = "info"
              }
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: tipo
            });
          });

      loader.hide();
    }
  },

  watch: {
    'inscricao.perfilSocioEconomico.escolaPublica': {
      handler() {
        if (!this.inscricao.perfilSocioEconomico.escolaPublica) {
          this.$refs.moduloAcaoAfirmativa.reiniciarAcaoAfirmativa();
        }
      }
    }
  }
};
</script>
