<template>
  <div>
    <v-card flat>
      <v-card-title class="tituloForm">
        Perfil Socioeconômico
      </v-card-title>
      <v-card-text>
        <v-row dense justify="center" align="center">
          <v-col cols="9">
            <span>Raça/Cor</span>
          </v-col>
          <v-col cols="3">
            <v-select
                :items="perfisRacaCor"
                v-model="perfilSocioEconomico.corRaca"
                item-text="label"
                item-value="value"
                :rules="[campoObrigatorioRule]"
            />
          </v-col>
        </v-row>
        <v-row dense justify="center" align="center">
          <v-col cols="9">
            <span>Declaração de renda per capita</span>
          </v-col>
          <v-col cols="3">
            <v-select
                :items="faixasRenda"
                v-model="perfilSocioEconomico.renda"
                item-text="label"
                item-value="value"
                :rules="[campoObrigatorioRule]"
            />
          </v-col>
        </v-row>
        <v-row dense justify="center" align="center">
          <v-col cols="9">
            <span>Você se declara quilombola?</span>
          </v-col>
          <v-col cols="3">
            <v-radio-group v-model="perfilSocioEconomico.quilombola"
                           dense
                           :rules="[campoObrigatorioRule]">
              <v-radio label="Sim" :value="true"/>
              <v-radio label="Não" :value="false"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row  dense justify="center" align="center">
          <v-col cols="9">
            <span>Você se declara pessoa com deficiência?</span>
          </v-col>
          <v-col cols="3">
            <v-radio-group v-model="perfilSocioEconomico.pcd"
                           dense
                           :rules="[campoObrigatorioRule]">
              <v-radio label="Sim" :value="true"/>
              <v-radio label="Não" :value="false"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row dense justify="center" align="center">
          <v-col cols="9">
            <span>Você cursou integralmente o ensino médio em escola pública ou escola comunitária de educação do campo?</span>
          </v-col>
          <v-col cols="3">
            <v-radio-group v-model="perfilSocioEconomico.escolaPublica"
                           :rules="[campoObrigatorioRule]"
                           dense
            >
              <v-radio label="Sim" :value="true"/>
              <v-radio label="Não" :value="false"/>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import rules from "@/commons/rules";

export default {
  name: "PerfilSocioEconomico",

  props: {
    perfilSocioEconomico: {type: Object, required: true}
  },

  methods: {
    ...rules
  },

  data() {
    return {
      faixasRenda: [
        { label: 'Não desejo declarar', value: 'NA' },
        { label: 'Até 0,5 salário mínimo', value: '0,5'},
        { label: 'Até 1 salário mínimo', value: '1'},
        { label: 'Até 1,5 salário mínimo', value: '1,5' },
        { label : 'Até 2 salários mínimos', value: '2' },
        { label: 'Até 2, 5 salários mínimos', value: '2,5'},
        { label: 'Até 3 salários mínimos', value: '3'},
        { label: 'Acima de 3 salários mínimos', value: 'V'}
      ],
      perfisRacaCor: [
        { label: 'Amarela', value: 'A' },
        { label: 'Branca', value: 'B'},
        { label: 'Indígena', value: 'I'},
        { label: 'Preta', value: 'P' },
        { label : 'Parda', value: 'R' }
      ],
    }
  },

  watch: {
    perfilSocioeconomico: {
      handler() {
        this.$emit('input', this.perfilSocioEconomico);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>