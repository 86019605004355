<template>
  <div>
    <Consulta
        ref="filtroConsulta"
        titulo="Histórico de Inscrição"
        :parametros="paramsFiltro"
        :colunas="headers"
        :consulta-callback="consulta"
    >
      <template v-slot:areaParametros>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
                v-model="paramsFiltro.nomeCandidato"
                dense
                label="Nome"
                name="nomeHistoricoInscricao"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="paramsFiltro.cpf"
                v-mask="'###########'"
                :counter="11"
                dense
                label="CPF"
                maxlength="11"
                name="cpfHistoricoInscricao"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
                v-model="paramsFiltro.processoOid"
                :items="processos"
                clearable
                dense
                item-text="nome"
                item-value="oid"
                label="Processos"
                outlined
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
                v-model="paramsFiltro.opcaoOid"
                :disabled="!paramsFiltro.processoOid"
                :items="opcoes"
                clearable
                dense
                item-text="nome"
                item-value="oid"
                label="Opção"
                outlined
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
                v-model="paramsFiltro.situacaoAntOid"
                :items="situacoes"
                clearable
                dense
                item-text="nome"
                item-value="oid"
                label="Situação Anterior"
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
                v-model="paramsFiltro.situacaoAtualOid"
                :items="situacoes"
                clearable
                dense
                item-text="nome"
                item-value="oid"
                label="Situação Atual"
                outlined
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
    </Consulta>
  </div>
</template>

<script>
import Consulta from "@/components/pages/admin/consultas/Consulta";
import historicoInscricaoService from "@/services/historicoInscricao.service";
import processoService from "@/services/processo.service";
import situacaoInscricaoService from "@/services/situacaoInscricao.service";
import opcaoService from "@/services/opcao.service";


export default {
  name: "historicoInscricao",
  components: {Consulta},

  data() {
    return {
      dialog: false,
      headers: [
        {text: "Nome", value: "nomeCandidato"},
        {text: "CPF", value: "cpf"},
        {text: "Justificativa", value: "codigoJustificativa"},
        {text:"Processo", value: "nomeProcesso"},
        {text: "Nome Opção", value: "nomeOpcao"},
        {text:"Situação Anterior", value: "codigoAnt"},
        {text:"Situação Atual", value: "codigoAtual"},
        {text: "CPF Responsável", value: "cpfResponsavel"},
        {text:"Perfil Responsável", value: "perfil"}
      ],
      paramsFiltro: {},
      processos: [],
      opcoes: [],
      situacoes: [],
      consulta: historicoInscricaoService.recuperarFiltro
    };
  },

  watch: {
    "paramsFiltro.processoOid": {
      handler(oid) {
        if (oid) {
          opcaoService
              .recuperarOpcoesConsulta(oid)
              .then(response => {
                this.opcoes = response.data;
              })
              .catch(() => {
              });
        } else {
          this.paramsFiltro.opcaoOid = null;
        }
      },
      deep: true
    }
  },

  async created() {
    await processoService
        .recuperarProcessosConsulta()
        .then(response => {
          this.processos = response.data;
        })
        .catch(() => {
        });

    await situacaoInscricaoService
        .recuperarSituacoesInscricao()
        .then(response => {
          this.situacoes = response.data;
        });
  },

  async mounted() {
    if (this.$route.params.processoOid) {
      this.paramsFiltro.processoOid = this.$route.params.processoOid;

      opcaoService
          .recuperarOpcoesConsulta(this.paramsFiltro.processoOid)
          .then(response => {
            this.opcoes = response.data;
          })
          .catch(() => {
          });

      this.$refs.filtroConsulta.mostrarResultado = true;

      await this.$refs.filtroConsulta.consultar();
    }
  },
};
</script>

<style scoped></style>
