<template>
  <v-container fluid>
    <v-row dense justify="space-between">
      <v-col cols="6">
        <v-text-field
            outlined
            v-model="inscricaoIVTHE.numeroEnem"
            :rules="[campoObrigatorioRule, tamanhoRule(12),processo.possuiPrefixoNumeroEnem ? prefixoRule(processo.listaPrefixoNumeroEnem) : true]"
            maxlength="12"
            v-mask="'############'"
            :counter="12"
            label="Número de inscrição no ENEM *"
            :hint="`Utilize o número ENEM referente ao ano: ${anosEnemPorPrefixos()}`"
            persistent-hint
            name="inscricaoNumeroEnem"
            validate-on-blur
            dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <EscolhaOpcaoSelect :inscricao="inscricao" :opcoes="opcoes"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import EscolhaOpcaoSelect from "@/components/pages/Inscricao/EscolhaOpcaoSelect";
import opcaoService from "@/services/opcao.service";

export default {
  components: {EscolhaOpcaoSelect},
  props: {
    processo: {type: Object, required: true},
    inscricao: {type: Object, required: true}
  },
  data() {
    return {
      inscricaoIVTHE: {
        inscricao: null,
        numeroEnem: '',
      },
      opcoes: []
    };
  },

  async created() {
    await opcaoService.recuperarOpcoesDisponiveisIVTHE(this.processo.oid)
        .then(response => {
          this.opcoes = response.data;
        }).catch();
  },

  watch: {
    inscricaoIVTHE: {
      handler() {
        this.inscricaoIVTHE.inscricao = this.inscricao;
        this.$emit("input", this.inscricaoIVTHE);
      },
      deep: true
    }
  },

  methods: {
    ...rules,
    anosEnemPorPrefixos() {
      const listaPrefixos = this.processo.listaPrefixoNumeroEnem
      let resultado;
      listaPrefixos.forEach(prefixo => {
        resultado = '20' + prefixo + ';  '
      })
      return resultado
    }
  }
};
</script>
