<template>
  <v-card flat v-if="recibo">
    <v-card-subtitle class="text-center">
      {{ "Data da Inscrição: " + recibo.dataCriacao }}
    </v-card-subtitle>

    <div>
      <v-card-title class="tituloForm">
        Dados do Candidato
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row dense>
          <v-col cols="auto" class="font-weight-bold">Nome:</v-col>
          <v-col> {{ recibo.nomeCandidato }}</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="auto" class="font-weight-bold">CPF:</v-col>
          <v-col>{{ recibo.cpf }}</v-col>
        </v-row>
      </v-card-text>
    </div>

    <div v-if="!!recibo.nomeSocial">
      <v-card-title class="tituloForm">
        Requerimentos
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row dense v-if="recibo.nomeSocial">
          <v-col cols="auto" class="font-weight-bold">Nome Social:</v-col>
          <v-col> {{ recibo.nomeSocial }}</v-col>
        </v-row>
      </v-card-text>
    </div>

    <div>
      <v-card-title class="tituloForm">
        Dados do Curso
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row dense>
          <v-col cols="auto" class="font-weight-bold">Curso:</v-col>
          <v-col> {{ recibo.nomeOpcao }}</v-col>
        </v-row>
        <v-row dense v-if="recibo.instrumentoMusical">
          <v-col cols="auto" class="font-weight-bold"
          >Instrumento Musical:
          </v-col>
          <v-col>{{ recibo.instrumentoMusical }}</v-col>
        </v-row>
        <v-row dense v-if="recibo.instrumentoMusical2">
          <v-col cols="auto" class="font-weight-bold"
          >Segundo Instrumento Musical:
          </v-col>
          <v-col>{{ recibo.instrumentoMusical2 }}</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="auto" class="font-weight-bold"
          >Número da Inscrição:
          </v-col>
          <v-col> {{ recibo.numeroInscricao }}</v-col>
        </v-row>
        <v-row dense v-if="recibo.matricula">
          <v-col cols="auto" class="font-weight-bold">Matrícula:</v-col>
          <v-col>{{ recibo.matricula }}</v-col>
        </v-row>
        <v-row dense v-if="recibo.numeroEnem">
          <v-col cols="auto" class="font-weight-bold">Número Enem:</v-col>
          <v-col>{{ recibo.numeroEnem }}</v-col>
        </v-row>
        <v-row dense v-if="recibo.instituicaoEnsinoNome">
          <v-col cols="auto" class="font-weight-bold"
          >Instituição de Origem:
          </v-col>
          <v-col>{{ recibo.instituicaoEnsinoNome }}</v-col>
        </v-row>
        <v-row dense v-if="recibo.anoIngressoInstituicaoOrigem">
          <v-col cols="auto" class="font-weight-bold"
          >Ano de Ingresso na Instituição de Origem:
          </v-col>
          <v-col>{{ recibo.anoIngressoInstituicaoOrigem }}</v-col>
        </v-row>
        <v-row dense v-if="recibo.anoConclusaoCursoAnterior">
          <v-col cols="auto" class="font-weight-bold"
          >Ano de Conclusão Graduação Anterior:
          </v-col>
          <v-col>{{ recibo.anoConclusaoCursoAnterior }}</v-col>
        </v-row>
        <v-row dense v-if="recibo.observacao">
          <v-col cols="auto" class="font-weight-bold"
          >Observação:
          </v-col>
          <v-col>{{ recibo.observacao }}</v-col>
        </v-row>
      </v-card-text>
    </div>

    <div v-if="recibo.necessidadesEspeciais">
      <v-card-title class="tituloForm">
        Necessidades Especiais
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row
            dense
            v-for="necessidadeEspecial in recibo.necessidadesEspeciais"
            :key="necessidadeEspecial.tipoNecessidadeEspecial"
        >
          <v-col>
            {{ necessidadeEspecial }}
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <div v-if="recibo.informacaoComplementar">
      <v-card-title class="tituloForm">
        Informações Complementares
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row dense>
          <v-col>
            {{ recibo.informacaoComplementar }}
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <div v-if="recibo.perfisSocioEconomicos">
      <v-card-title class="tituloForm">
        Perfil Socioeconômico
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row
            dense
            v-for="perfil in recibo.perfisSocioEconomicos"
            :key="perfil"
        >
          <v-col class="ml-3">
            <li>{{ perfil }}</li>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <div v-if="recibo.acaoAfirmativa">
      <v-card-title class="tituloForm">
        Ação Afirmativa
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row dense>
          <v-col>
            {{ recibo.acaoAfirmativa }}
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <div v-if="recibo.acoesAfirmativas">
      <v-card-title class="tituloForm">
        Ação Afirmativa
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row
            dense
            v-for="acao in recibo.acoesAfirmativas"
            :key="acao"
        >
          <v-col class="ml-3">
            <li>{{ acao }}</li>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <div v-if="recibo.documentosEnviados">
      <v-card-title class="tituloForm">
        Documentos Enviados
      </v-card-title>
      <v-card-text class="text-body-1">
        <v-row
            dense
            v-for="documento in recibo.documentosEnviados"
            :key="documento"
        >
          <v-col>
            {{ documento }}
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <v-container fluid>
      <v-row justify="center">
        <v-dialog width="600" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-container>
              <v-row class="justify-center">
                <v-btn v-bind="attrs" v-on="on" class="ma-2" color="error">
                  Cancelar Inscrição
                </v-btn>
                <v-btn
                    class="ma-2"
                    color="success"
                    outlined
                    @click="recuperarReciboPdf"
                >
                  Imprimir Recibo
                </v-btn>
              </v-row>
            </v-container>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-card-title class="primary justify-start white--text">
                Cancelar Inscrição
              </v-card-title>
              <v-card class="text-center" flat>
                <v-card-text>
                  <span class="subtitle-1">
                    Tem certeza que deseja cancelar sua inscrição nesse
                    processo?
                  </span>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                      color="primary"
                      @click="
                      dialog.value = false;
                      cancelarInscricao();
                    "
                  >
                    Sim
                  </v-btn>
                  <v-btn color="error" @click="dialog.value = false">
                    Não
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card>
          </template>
        </v-dialog>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import reciboService from "@/services/recibo.service";
import inscricaoService from "@/services/inscricao.service";

export default {
  name: "Recibo",

  props: {
    processoOid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      recibo: null
    };
  },

  async created() {
    let loader = this.$loading.show();

    await reciboService.recuperarRecibo(this.processoOid).then(response => {
      this.recibo = response.data;
    });

    loader.hide();
  },

  computed: {
    corPrimaria() {
      return {
        color: this.$vuetify.theme.currentTheme.primary
      };
    }
  },

  methods: {
    async cancelarInscricao() {
      let loader = this.$loading.show();
      await inscricaoService
          .cancelarInscricaoUsuarioLogado(this.processoOid)
          .then(() => {
            this.$router.push("/");
            this.exibirAviso({
              mensagem: "Inscrição cancelada com sucesso!",
              tipo: "success"
            });
          })
          .catch(() => {
          });
      loader.hide();
    },

    async recuperarReciboPdf() {
      let loader = this.$loading.show();

      await reciboService
          .recuperarReciboPdf(this.processoOid)
          .then(response => {
            const file = new Blob([response.data], {type: "application/pdf"});
            const fileURL = URL.createObjectURL(file);
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.download = "recibo.pdf";
            fileLink.click();
          });

      loader.hide();
    }
  }
};
</script>

<style></style>
