import Vue from "vue";


export default {

     recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
         return Vue.axios.post("historicoInscricao/filtro", {
            params: {
                nomeCandidato: paramsFiltro.nomeCandidato,
                cpf: paramsFiltro.cpf,
                cpfResponsavel: paramsFiltro.cpfResponsavel,
                processoOid: paramsFiltro.processoOid,
                opcaoOid: paramsFiltro.opcaoOid,
                situacaoAntOid: paramsFiltro.situacaoAntOid,
                situacaoAtualOid: paramsFiltro.situacaoAtualOid
            },
            pagina,
            numElementosPagina,
            ordenacao
        });
    }
}

