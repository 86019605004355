<template>
  <div>
    <v-card flat>
      <v-card-title class="tituloForm">
        Ação Afirmativa
      </v-card-title>
      <v-card-text>
        <v-row  dense justify="center" align="center">
          <v-col cols="9">
            <span>Deseja concorrer às vagas reservadas para ação afirmativa de acordo com a Lei 12.711/12 e as suas alterações?</span>
          </v-col>
          <v-col cols="3">
            <v-radio-group dense v-model="acaoAfirmativa.concordouVagasDaLei" :rules="[campoObrigatorioRule]">
              <v-radio label="Sim" :value="true"/>
              <v-radio label="Não" :value="false"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="acaoAfirmativa.concordouVagasDaLei" dense justify="center" align="center"
        >
          <v-col
              cols="9"
          >
            <span>
              Deseja concorrer à ação afirmativa - Vagas reservadas para quem tenha cursado integralmente o ensino médio em escola pública ou escola comunitária de educação do campo de acordo com a Lei 12.711/12 e as suas alterações?
            </span>
          </v-col>
          <v-col
              cols="3"
          >
            <v-radio-group dense disabled v-model="acaoAfirmativa.modalidadeEscolar" :rules="[campoObrigatorioRule]">
              <v-radio label="Sim" :value="true"/>
              <v-radio label="Não" :value="false"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="acaoAfirmativa.modalidadeEscolar"
               justify="center" align="center">
          <v-col
              md="9"
              cols="6"
          >
            <span>
              Deseja concorrer à ação afirmativa - Vagas reservadas para quem possuir renda familiar inferior ou igual à 1 salário mínimo per capita de acordo com a Lei 12.711/12 e as suas alterações?
            </span>
          </v-col>
          <v-col
              md="3"
              cols="6"
          >
            <v-radio-group v-if="['0,5', '1'].includes(perfilSocioEconomico.renda)"
                           v-model="acaoAfirmativa.modalidadeRenda"
                           dense
                           :rules="[campoObrigatorioRule]"
            >
              <v-radio label="Sim" :value="true"/>
              <v-radio label="Não" :value="false"/>
            </v-radio-group>
            <v-alert dense outlined v-else color="info">
              Apenas candidato que declarou renda inferior ou igual à 1 salário mínimo pode concorrer.
            </v-alert>
          </v-col>

          <v-col
              md="9"
              cols="6"
          >
            <span>
              Deseja concorrer à ação afirmativa - Vagas reservadas para quem se declara pertencente ao grupo de Pretos, Pardos e Indígenas de acordo com a Lei 12.711/12 e as suas alterações?
            </span>
          </v-col>
          <v-col
              md="3"
              cols="6"
          >
            <v-radio-group v-if="['P', 'R', 'I'].includes(perfilSocioEconomico.corRaca)"
                           v-model="acaoAfirmativa.modalidadeRacial"
                           dense
                           :rules="[campoObrigatorioRule]"
            >
              <v-radio label="Sim" :value="true"/>
              <v-radio label="Não" :value="false"/>
            </v-radio-group>
            <v-alert dense outlined v-else color="info">
              Apenas candidato que se declara preto, pardo ou indígena pode concorrer.
            </v-alert>
          </v-col>

          <v-col
              md="9"
              cols="6"
          >
            <span>
              Deseja concorrer à ação afirmativa - Vagas reservadas para quem se declara pessoa com deficiência de acordo com a Lei 12.711/12 e as suas alterações?
            </span>
          </v-col>
          <v-col
              md="3"
              cols="6"
          >
            <v-radio-group v-if="perfilSocioEconomico.pcd"
                           v-model="acaoAfirmativa.modalidadePCD"
                           dense
                           :rules="[campoObrigatorioRule]"
            >
              <v-radio label="Sim" :value="true"/>
              <v-radio label="Não" :value="false"/>
            </v-radio-group>
            <v-alert dense outlined v-else color="info">
              Apenas candidato que se declara pessoa com deficiência pode concorrer.
            </v-alert>
          </v-col>

          <v-col
              md="9"
              cols="6"
          >
            <span>
              Deseja concorrer à ação afirmativa - Vagas reservadas para quem se declara quilombola de acordo com a Lei 12.711/12 e as suas alterações?
            </span>
          </v-col>
          <v-col
              md="3"
              cols="6"
          >
            <v-radio-group v-if="perfilSocioEconomico.quilombola"
                           v-model="acaoAfirmativa.modalidadeQuilombola"
                           dense
                           :rules="[campoObrigatorioRule]"
            >
              <v-radio label="Sim" :value="true"/>
              <v-radio label="Não" :value="false"/>
            </v-radio-group>
            <v-alert dense outlined v-else color="info">
               Apenas candidato que se declara quilombola pode concorrer.
            </v-alert>
          </v-col>
        </v-row>
        <v-checkbox
            v-if="acaoAfirmativa.concordouVagasDaLei"
            v-model="acaoAfirmativa.estouCiente"
            label="Estou ciente de que, ao optar por concorrer em qualquer uma das modalidades acima, caso venha a ser classificado, deverei satisfazer às exigências editalícias referentes à modalidade em que fui classificado, sob pena de eliminação do processo seletivo."
            color="success"
            dense
            :rules="[campoObrigatorioCheckBoxRule]"
        ></v-checkbox>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import rules from "@/commons/rules";

export default {
  name: "AcaoAfirmativa",

  props: {
    perfilSocioEconomico: {type: Object, required: true},
    acaoAfirmativa: {type: Object, required: true}
  },

  methods: {
    ...rules,

    reiniciarAcaoAfirmativa() {
      this.acaoAfirmativa.estouCiente = false
      this.acaoAfirmativa.modalidadeEscolar = null
      this.acaoAfirmativa.modalidadeRenda = null
      this.acaoAfirmativa.modalidadePCD = null
      this.acaoAfirmativa.modalidadeRacial = null
      this.acaoAfirmativa.modalidadeQuilombola = null
    }
  },

  watch: {
    acaoAfirmativa: {
      handler() {
        this.$emit('input', this.acaoAfirmativa);
      },
      deep: true
    },

    "acaoAfirmativa.concordouVagasDaLei": {
      handler() {
        if (this.acaoAfirmativa.concordouVagasDaLei) {
          this.acaoAfirmativa.modalidadeEscolar = true
        } else {
          this.reiniciarAcaoAfirmativa();
        }
      }
    },

    "acaoAfirmativa.modalidadeEscolar": {
      handler() {
        if (!this.acaoAfirmativa.modalidadeEscolar) {
          this.reiniciarAcaoAfirmativa();
        }
      }
    },

    "perfilSocioEconomico": {
      handler() {
        if (!this.perfilSocioEconomico.pcd) {
          this.acaoAfirmativa.modalidadePCD = null
        }

        if (!this.perfilSocioEconomico.quilombola) {
          this.acaoAfirmativa.modalidadeQuilombola = null
        }

        if (!this.perfilSocioEconomico.escolaPublica) {
          this.acaoAfirmativa.estouCiente = false
          this.acaoAfirmativa.modalidadeRenda = null
          this.acaoAfirmativa.modalidadeRacial = null
          this.acaoAfirmativa.modalidadePCD = null
          this.acaoAfirmativa.modalidadeQuilombola = null
          this.acaoAfirmativa.modalidadeEscolar = null
        }

        if (! ['P', 'R', 'I'].includes(this.perfilSocioEconomico.corRaca)) {
          this.acaoAfirmativa.modalidadeRacial = null
        }

        if (! ['0,5','1'].includes(this.perfilSocioEconomico.renda)) {
          this.acaoAfirmativa.modalidadeRenda = null
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>